import React from 'react';
import BreadCrumbSelector from '../../components/BreadCrumb/BreadCrumbSelector';
import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';

import TireOffers from '../../components/Tires/TireOffers';
import loadable from '@loadable/component';
import { standortUrlSelector } from '../../../modules/selectors/url';
import Image from '../../components/Image/Image';
import TireConfigurator from '../../components/TireConfigurator';
import { useParams } from 'react-router-dom';

import { validSeasonsSelector } from '../../../modules/selectors/standort/tires';
import { findSeasonOption } from '../../../helpers/tiresHelper';
import { InternalLink } from '../../components/form-elements/Link';
import { TecarConfiguratorCard, TireInquiryCard } from '../../components/ServiceStationFinder/ServiceStationCards';
import { useStandortHistory } from '../../components/VHost/StandortHistory';

const CMSTeaser = loadable(() => import('../../components/CMSTeaser/CMSTeaser'));
const ServiceStationFinder = loadable(() => import('../../components/ServiceStationFinder/ServiceStationFinder'));
const NotFound = loadable(() => import('../ErrorPages/NotFound/NotFound'));

function TireConfiguratorPage() {
    const { seasonUrl } = useParams();
    const { label: seasonLabel } = findSeasonOption({ season: seasonUrl });
    const history = useStandortHistory();
    const standortUrl = useSelector(standortUrlSelector);
    const markenStandort = useSelector(markenStandortSelector);
    const validSeasons = useSelector(validSeasonsSelector);

    if (!validSeasons.includes(seasonUrl))
        return (
            <>
                <BreadCrumbSelector additionalCrumbs={[{ label: '404', url: `/notFound` }]} />
                <NotFound />
            </>
        );

    return (
        <>
            <BreadCrumbSelector />
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <div className="uk-grid" data-uk-grid="">
                        <div className="uk-width-1-1 uk-width-1-2@s uk-width-expand@m">
                            <h1 className="uk-text-primary uk-text-light">
                                {seasonLabel}
                                {!markenStandort.isTechno && <span> von {markenStandort.anschrift.firma}</span>}
                            </h1>
                            {markenStandort.isTechno ? (
                                <h2 className="uk-text-light">Schnell und einfach {seasonLabel} finden</h2>
                            ) : markenStandort.areaTitle !== 'Hamburg' ? (
                                <h2 className="uk-text-light">
                                    Neue {seasonLabel} in {markenStandort.areaTitle} finden & direkt Termin vereinbaren
                                </h2>
                            ) : (
                                <h2 className="uk-text-light">
                                    Neue {seasonLabel} finden & direkt Termin vereinbaren
                                </h2>
                            )}
                            <p>
                                Geben Sie einfach Ihre Reifendaten ein. Sofort zeigen wir Ihnen passende {seasonLabel} zur Auswahl, die Sie
                                reservieren oder online bestellen können. Anschließend können Sie
                                {markenStandort.isTechno ? ' deutschlandweit' : ''} eine Werkstatt in Ihrer Nähe finden und einen Termin zum
                                Reifenwechsel vereinbaren.
                            </p>
                        </div>
                        <div className="uk-width-1-1 uk-width-1-2@s uk-width-auto@m">
                            <Image
                                className="uk-width-1-1"
                                resizeHeight={220}
                                style={{ minHeight: 220 }}
                                src={`https://techno-images.s3.eu-central-1.amazonaws.com/aktionen/reifen/${seasonUrl}.jpg`}
                                alt={seasonUrl}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <TireConfigurator
                showZipInput={markenStandort.isTechno}
                season={seasonUrl}
                onSeasonChange={({ value }) => {
                    history.push(`/${value}`);
                }}
            />
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    {standortUrl === 'de' ? (
                        <div className="uk-inline">
                            <Image
                                src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/TECAR_REIFEN.jpg"
                                alt="TECAR Reifen"
                                width={1180}
                                height={489}
                            />
                            <div className="uk-overlay uk-position-bottom-right">
                                <InternalLink to="/home/tecarLandingpage/reifen-finden" newStandortUrl={standortUrl} modifier="secondary">
                                    HIER ANGEBOTE ANSEHEN
                                </InternalLink>
                            </div>
                        </div>
                    ) : (
                        <TireOffers />
                    )}
                </div>
            </section>
            {standortUrl === 'de' && (
                <section className="uk-section uk-section-default uk-section-small">
                    <div className="uk-container">
                        <h2 className="uk-text-light">Werkstatt in Ihrer Nähe finden und direkt Termin vereinbaren</h2>
                        <p>
                            Geben Sie einfach Ihre Postleitzahl & die Automarke ein. Sofort zeigen wir Ihnen eine{' '}
                            <b>Werkstatt in Ihrer Nähe</b>. Hier können Sie neue {seasonLabel} kaufen und einen{' '}
                            <b>Termin zum Reifenwechsel</b> vereinbaren.
                        </p>
                        <ServiceStationFinder
                            gesellschafter
                            tecar
                            wheels
                            redirectUrl={`/${seasonUrl}`}
                            trailingCards={({ serviceStationsLength }) => (
                                <>
                                    <TecarConfiguratorCard serviceStationsLength={serviceStationsLength} />
                                    <TireInquiryCard serviceStationsLength={serviceStationsLength} />
                                </>
                            )}
                        />
                    </div>
                </section>
            )}
            <CMSTeaser previewCategory="tires" isMobile={false} />
        </>
    );
}

export default TireConfiguratorPage;
