import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { standortUrlSelector } from '../../../modules/selectors/url';
import { tiresFilterValuesSelector } from '../../../modules/selectors/standort/tires';
import { apiClient, useAsyncFunction } from '../../../helpers/apiHelperV2';
import CardSlider from '../layout/CardSlider';
import TireCard from '../layout/TireCard';
import InlineSpinner from '../Spinner/InlineSpinner';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';

function TireOffers() {
    const { seasonUrl } = useParams();
    const standortUrl = useSelector(standortUrlSelector);
    const tiresFilterValues = useSelector(tiresFilterValuesSelector);

    const tireOffersResponse = useAsyncFunction(
        async () =>
            apiClient.get(`/api/v1/standorte/${standortUrl}/tires/offers`, {
                params: {
                    saison: seasonUrl,
                    fahrzeug: tiresFilterValues.fahrzeug,
                    breite: tiresFilterValues.breite,
                    hoehe: tiresFilterValues.hoehe,
                    durchmesser: tiresFilterValues.durchmesser,
                    shuffle: true,
                    limit: 3,
                },
            }),
        [seasonUrl, standortUrl, tiresFilterValues]
    );

    return (
        <ReduxSuspense response={tireOffersResponse} error={null} fallback={<InlineSpinner />}>
            {data => {
                if (!data?.data?.tireOffers) return null;
                return (
                    <section className="uk-section uk-section-default uk-section-small">
                        <div className="uk-container">
                            <h2 className="uk-text-light">
                                Unsere beliebtesten <span className="uk-text-capitalize">{seasonUrl}</span>
                            </h2>
                            <CardSlider>
                                {data.data.tireOffers.map(tire => (
                                    <li key={tire._id}>
                                        <TireCard tire={tire} />
                                    </li>
                                ))}
                            </CardSlider>
                        </div>
                    </section>
                );
            }}
        </ReduxSuspense>
    );
}

export default TireOffers;
