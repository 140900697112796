import React, { useState } from 'react';
import loadable from '@loadable/component';
import Image from '../Image/Image';
import Modal from '../Modal/Modal';
import Button from '../form-elements/Button';
import { gripSymbols, tireLabels } from '../../../helpers/tiresHelper';

const TireInformationModalBody = loadable(() => import('./TireInformationModalBody'));

function TireInformationModal({ tire }) {
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    return (
        <>
            <Button className="uk-text-muted" hideArrow onClick={() => setInfoModalOpen(true)} modifier="link">
                <div className="uk-grid uk-grid-small uk-child-width-auto uk-flex uk-flex-middle" data-uk-grid="">
                    {tireLabels.map(({ imgSrc, alt, format }, i) => (
                        <div key={i}>
                            <div className="uk-tile uk-tile-muted uk-padding-small uk-text-bold uk-text-large">
                                <Image src={imgSrc} alt={alt} /> <span>{format(tire)}</span>
                            </div>
                        </div>
                    ))}
                    {gripSymbols
                        .filter(({ filter }) => filter(tire))
                        .map(({ imgSrc, alt }, i) => (
                            <div key={i}>
                                <div className="uk-tile uk-tile-muted" style={{ padding: '5px' }}>
                                    <Image resizeHeight={36} resizeWidth={36} src={imgSrc} alt={alt} />
                                </div>
                            </div>
                        ))}
                </div>
            </Button>
            <Modal fullWidth isOpen={infoModalOpen} contentLabel="Kontakt Modal" onRequestClose={() => setInfoModalOpen(false)}>
                <Button modifier="text" className="uk-modal-close-default" onClick={() => setInfoModalOpen(false)}>
                    <i data-uk-icon="close" />
                </Button>
                <div className="uk-modal-body">
                    <TireInformationModalBody />
                </div>
            </Modal>
        </>
    );
}

export default TireInformationModal;
