import React, { useMemo } from 'react';
import Image from '../Image/Image';
import Ribbon from '../product-list/ribbon';
import { InternalLink } from '../form-elements/Link';
import Currency from '../../../helpers/currency';
import TireInformationModal from '../Tires/TireInformationModal';

function TireCard({ tire }) {
    const to = useMemo(() => {
        return tire.href || `/${tire.categories[0]}/${tire.id}`;
    }, [tire]);

    return (
        <div className="uk-card uk-card-default uk-card-small uk-flex uk-flex-column">
            <div className="uk-card-media-top uk-flex uk-flex-center">
                <Image src={tire.imagelink} alt={tire.bezeichnung} resizeHeight={235} fit="cover" />
            </div>
            <div className="uk-card-body uk-flex uk-flex-column uk-flex-1">
                <Ribbon product={tire} />
                <InternalLink to={to} modifier="reset">
                    <h3 className="uk-card-title uk-text-center">
                        <Currency quantity={tire.selectedBezugsquelle.vk_preis} />
                        <span className="uk-text-light uk-text-small">/Stück </span>
                    </h3>
                    <div className="uk-background-muted uk-text-center uk-text-bold uk-padding-small">{tire.hersteller.toUpperCase()}</div>
                    <div className="uk-text-left uk-margin">
                        {tire.warengruppe} <br />
                        {tire.bezeichnung} <br />
                        Geschwindigkeitsindex: {tire.geschwindigkeitsindex} bis {tire.tragfaehigkeit} km/h
                    </div>
                </InternalLink>
                <div className="uk-margin-auto-top">
                    <InternalLink to={to} modifier="reset">
                        <TireInformationModal tire={tire} />
                    </InternalLink>
                    <InternalLink to={to} fullWidth modifier="primary" className="uk-margin">
                        ZUM PRODUKT
                    </InternalLink>
                </div>
            </div>
        </div>
    );
}

export default TireCard;
